body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  -moz-osx-font-smoothing: grayscale;
}

/* CSS Variables */
:root {
  --primary: #ffffff;
  --dark: #353b40;
  --medium: #9a9da0;
  --facebook: #4968ad;
  --youtube: #eb3223;
  --button: #4968ad;
  --black: #000000;
}

.content {
  padding-left: 5%;
  padding-right: 5%;
}

.box {
  background: #fff;
  margin: 0 0 30px;
  border: solid 1px #e6e6e6;
  padding: 30px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.bg-info {
  color: var(--medium);
  background-color: var(--dark) !important;
}

.card {
  min-width: 10rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  min-width: 14rem;
  padding: 1rem;
}
.card-img-top:hover {
  border-radius: 0% !important;
  text-decoration: none;
  box-shadow: 0 4px 20px 2px rgba(39, 67, 210, 0.8);
  transform: scale(1.1);
}

/* design screen */
.checkbox-label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
.checkbox-label input {
  margin-right: 10px;
}
.customize-option-item {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.remove-button {
  margin-top: 5px;
  margin-bottom: 5px;
}
.line {
  color: var(--youtube); /* Red color */
  border: 1px solid var(--youtube); /* Ensures the hr line reflects the color */
}

main {
  flex: 1;
  min-height: 100vh;
  background-color: #ebeded;
}

.btn-primary,
.btn-outline-primary {
  background-color: var(--button);
  color: var(--primary);
}

.checkout-steps > div {
  border-bottom: 0.2rem solid #a0a0a0;
  color: #a0a0a0;
}
.checkout-steps > div.active {
  border-bottom: 0.2rem solid var(--youtube);
  color: #353b40;
}

/* image zoom - product screen */
.container1 {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.img-large {
  width: 500px;
  object-fit: contain;
  position: relative; /* brings main image forward */
  z-index: 2;
}
.img-thumbnail {
  height: 80px;
  width: 80px;
}
.thumbnail {
  display: flex;
  flex-direction: row;
  width: 80px;
  height: 120px;
  row-gap: 20px;
}
.thumbnail:hover {
  border-radius: 0% !important;
  text-decoration: none;
  box-shadow: 0 4px 20px 2px rgba(39, 67, 210, 0.8);
  transform: scale(1.1);
}

/* *********************** Header ************************ */
.header {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: var(--bold);
  padding-left: 5%;
  padding-right: 5%;
}
.navbar-brand > img {
  height: 100px;
  width: 250px;
  margin-top: -2rem;
  margin-bottom: -2rem;
}

/****** Dropdown ******/
.dropdown {
  display: block;
  position: relative;
}
.dropdown-content {
  position: absolute;
  display: none;
  right: 0;
  min-width: 12rem;
  padding: 1rem;
  z-index: 1;
  background-color: #203040;
  margin: 0;
  margin-top: 0.4rem;
  border-radius: 0.5rem;
}
.dropdown:hover .dropdown-content {
  display: block;
}

/* Custom CSS for success variant dropdown */
.nav-dropdown-success .dropdown-menu {
  background-color: #d4edda;
  color: #155724;
}
.nav-dropdown-success .dropdown-item:hover,
.nav-dropdown-success .dropdown-item:focus {
  background-color: #c3e6cb;
  color: #155724;
}
/* Custom CSS for success variant dropdown title */
.nav-dropdown-success .nav-link {
  color: #28a745 !important; /* Green color for the title */
}

.facebook {
  color: var(--facebook);
  font-weight: bold;
}

footer {
  text-decoration: none;
  font-size: 1rem;
  padding: 1rem;
  margin-top: 1rem;
  background: #353b40;
  color: #ffffff;
  text-align: center;
}
.bottom-footer {
  text-decoration: none;
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
  background: black;
  color: #ffffff;
  text-align: center;
}

/****** footer social icons ******/
a.socialIcon {
  margin: 0 1rem;
}
.socialIcon a:hover {
  color: var(--primary);
}
.socialIcon {
  margin: 0 1rem;
  transition: transform 250ms;
}
a.facebookIcon {
  color: var(--medium);
}
a.youtubeIcon {
  color: var(--medium);
}
a.email {
  color: var(--medium);
}

/****** product sidebar ******/
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: var(--medium);
  transition: 1s;
  z-index: 9999;
}

.sidebar header,
.sidebar footer {
  display: none;
}

.sidebar .container {
  height: 100%;
  max-width: initial;
  margin: 0;
  padding: 0;
}

/* if a X button is added to sidebar */
.close-btn {
  position: absolute;
  color: #000;
  background-color: var(--medium);
  border: none;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.5s;
}

.sidebar-open .close-btn {
  transform: rotate(45deg);
}

.bar {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #333;
  transition: transform 0.3s ease;
}

.bar:first-child {
  top: 0;
  transform-origin: top left;
}

.bar:last-child {
  bottom: 0;
  transform-origin: bottom left;
}

.sidebar-open .bar:first-child {
  transform: rotate(45deg);
}

.sidebar-open .bar:last-child {
  transform: rotate(-45deg);
}
/* end sidebar */

.send-reply-button {
  margin-right: 20px; /* Adjust the value to increase/decrease the gap */
}

.hrLine {
  border-color: var(--medium);
}

.header {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: #353b40;
}
.bottom-header {
  text-decoration: none;
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
  background: black;
  color: var(--primary);
  text-align: center;
  justify-content: space-around;
}

/* .jumbotron1 {
  background-image: url('./images/pens.png');
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 6em 6em;
  color: var(--dark);
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
} */

.jumbotron1 {
  background-image: url('./images/blank.gif');
  transition: 1000ms;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 4.5em 6em;
  color: var(--medium);
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px blue;
}

.parallax {
  background-image: url('./images/cover pens.png');
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* gabewd in footer link color */
#linked {
  color: var(--medium);
  text-decoration: none;
}

.lens {
  height: 125px;
  width: 125px;
  border: 2px var(--dark);
}

.noWrap {
  white-space: nowrap;
}

.nav-categories {
  display: none;
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.product {
  border: 1px #353b40 solid;
  margin: 1rem;
  background: var(--primary);
}
.product img {
  width: 100%;
  max-width: 400px;
}
.product-info {
  padding: 1rem;
}

.pointer {
  cursor: pointer;
}

/* paypal and stripe logo */
.payment-option {
  display: flex;
  align-items: center;
}
.payment-option .fab {
  margin-left: 10px; /* Adjust the margin as needed */
  font-size: 30px; /* Adjust the font size as desired */
}

/* Pagination */
.pagination a {
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pagination a.active {
  font-weight: bold;
}

.rating span {
  color: var(--facebook);
}

.img-large {
  max-width: 100%;
}
.img-thumbnail {
  height: 80px;
}

.small-screen {
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-top: 1rem;
}

/* turning gif */
.img-responsive {
  display: block !important;
  max-width: 100% !important;
  height: auto !important;
}

button.thumbnail {
  padding: 0;
}

.youtube {
  color: var(--youtube);
  font-weight: bold;
}

/* ProductMagScreen thumbnails left column */
.container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.left {
  display: flex;
  height: 50vh;
}
.left_1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.img_wrap {
  width: 80px;
  height: 80px;
  border: 1px solid #eee;
  cursor: pointer;
}
.img_wrap img {
  width: 70px;
  height: 70px;
  object-fit: contain;
}
.left_2 img {
  width: 500px;
  height: 100%;
  object-fit: contain;
}
.text {
  text-align: center;
  padding-top: 20px;
}
.active1 {
  border: 2px solid rgba(39, 67, 210, 0.8);
}

.flush p {
  color: var(--black);
}

/* ************************************************************* */

@media screen and (max-width: 1450px) {
  .jumbotron1 {
    background-image: url('./images/blank.gif');
    transition: 1000ms;
    background-repeat: no-repeat;
    object-fit: cover;
    background-size: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 4.5em 6em;
    color: var(--medium);
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px blue;
  }
}

/* ************************************************************* */

@media screen and (max-width: 992px) {
  .jumbotron1 {
    background-image: url('./images/blank.gif');
    transition: 1000ms;
    background-repeat: no-repeat;
    object-fit: cover;
    background-size: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 4.5em 6em;
    color: var(--medium);
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px blue;
  }
}

/* ************************************************************* */

@media screen and (max-width: 820px) {
  .jumbotron1 {
    background-image: url('./images/blank.gif');
    transition: 1000ms;
    background-repeat: no-repeat;
    object-fit: cover;
    background-size: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 3em 3em;
    color: var(--medium);
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px blue;
  }
}

/* ************************************************************* */

@media screen and (max-width: 767px) {
  main {
    min-height: 120vh;
  }

  .header {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: var(--bold);
  }
  .navbar-brand > img {
    height: 50px;
    width: 100px;
  }

  .about {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 1.5rem;
  }

  .align-items-center {
    font-size: xx-small;
  }

  .btn-primary {
    font-size: xx-small;
  }

  .bottom-header {
    display: none;
  }

  .box {
    background: #fff;
    margin: 0 0 10px;
    border: solid 1px #e6e6e6;
    padding: 10px;
    margin: 10px;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    font-size: xx-small;
    height: auto;
  }

  .button-group {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }

  .content {
    padding-right: auto;
  }
  .card {
    display: flex;
    flex-direction: row;
    text-align: center;
    max-width: 28rem;
    max-height: 300px;
  }
  .card-body {
    max-height: 220px;
  }
  .card-title {
    font-size: 10px;
    max-height: 230px;
  }
  .card-img-top {
    overflow: hidden;
    min-height: 200px;
    max-height: 200px;
    min-width: 110px;
    max-width: 120px;
  }

  .checkbox-label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .checkbox-label input {
    margin-right: 10px;
  }

  .customize-option-item {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  .remove-button {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  h1 {
    font-size: 0.1rem;
    padding: 0.5rem 0;
  }

  h2 {
    font-size: 0.8rem;
    padding: 0.5rem 0;
  }

  h3 {
    padding: 1rem 0;
    font-size: smaller;
  }

  .flex-column {
    width: 100%;
  }

  .img-large {
    max-width: 325px;
  }
  .img-responsive {
    max-width: 100%;
    height: auto;
  }

  .jumbotron1 {
    background-image: url('./images/pens.png');
    background-repeat: no-repeat;
    object-fit: cover;
    background-size: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 4em 5em;
    color: var(--primary);
    text-align: center;
    font-size: 0.9rem;
  }

  .parallax {
    display: none;
  }

  .list-group-item {
    padding: 4px !important; /* Reduce padding */
    word-wrap: break-word; /* Allow long text to wrap */
  }
  .list-group-item > .row {
    margin: 0; /* Prevent unnecessary space between rows */
  }
  .list-group {
    overflow: auto; /* Allow scrolling if content overflows */
  }

  .mt-3 {
    padding: 15px;
  }

  .mx-1 {
    font-size: xx-small;
    padding: 10px;
  }

  .nav-categories {
    display: flex;
  }

  .products {
    border: 1px #353b40 solid;
    margin: 0.5rem;
  }

  .rating span {
    margin: 0.1rem;
    font-size: 6px;
  }

  .search {
    font-size: smaller;
  }

  .search h3 {
    font-size: 12px;
  }

  .thumbnail {
    height: 65px;
    width: 45px;
    margin-bottom: 20px;
    margin-left: 5px;
  }
}
